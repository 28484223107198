<template>
  <div>
    <div class="background-area">
      <img src="../../assets/banner-4.jpg" alt="banner-3">
      <div class="caption">
        <h1>南棠WMS仓储管理系统</h1>
      </div>
    </div>

    <h1>南棠 WMS</h1>

    <div>
      <img src="../../assets/function-intro/wms/wms.png" alt="wms">
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "../../components/footer/footer"
export default {
  name: "Wms",
  components: {
    Footer
  }
}
</script>

<style scoped>
  .background-area {
    position: relative;
  }
  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    color: white;
  }
</style>
